<template>
  <v-dialog
    :value="true"
    persistent
    max-width="30rem"
    transition="dialog-transition"
  >
    <v-card>
      <validation-observer
        ref="reauthenticate"
        v-slot="{ valid }"
        tag="form"
        @submit.prevent="reauthenticate"
      >
        <v-card-title>
          Reauthenticate
          <v-spacer />
          <v-btn
            icon
            @click="$emit('close')"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          Please reauthenticate with your current password to complete this request.
        </v-card-text>
        <v-card-text>
          <v-alert
            v-if="reauthenticateError"
            dense
            outlined
            border="left"
            type="error"
            class="mb-0"
          >
            {{ reauthenticateError.message }}
          </v-alert>
          <validation-provider
            v-slot="{ errors }"
            rules="required|min:6"
            name="Password"
          >
            <v-text-field
              v-model="password"
              label="Password"
              :type="showPassword ? 'text' : 'password'"
              autocomplete
              required
              prepend-icon="mdi-key"
              :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :error-messages="errors"
              @click:append="showPassword = !showPassword"
            />
          </validation-provider>
        </v-card-text>
        <v-card-actions>
          <v-btn
            type="submit"
            color="primary"
            block
            :loading="reauthenticating"
            :disabled="!valid"
          >
            Proceed
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { getAsyncCurrentUser } from '@/plugins/firebase'
export default {
  name: 'Reauthenticate',
  data: () => ({
    password: '',
    showPassword: false,
    reauthenticateError: null,
    reauthenticating: false
  }),
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    async reauthenticate() {
      this.reauthenticating = true
      const user = await getAsyncCurrentUser()
      const credential = this.$firebase.auth.EmailAuthProvider.credential(this.user.email, this.password)
      user.reauthenticateWithCredential(credential)
        .then(() => {
          this.reauthenticating = false
          this.reauthenticateError = null
          this.$emit('success')
        })
        .catch((error) => {
          this.reauthenticateError = error
          this.reauthenticating = false
        })
    }
  }
}
</script>
