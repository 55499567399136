<template>
  <validation-observer
    ref="form"
    v-slot="{ valid, dirty }"
    tag="form"
    @submit.prevent="submit"
  >
    <v-alert
      v-if="error"
      dense
      outlined
      border="left"
      type="error"
      class="mb-0"
    >
      {{ error.message }}
    </v-alert>
    <v-list-item class="wrapped">
      <v-list-item-content>
        <validation-provider
          v-slot="{ errors }"
          rules="required|min:6"
          name="Password"
          vid="confirmation"
        >
          <v-text-field
            v-model="password"
            label="Password"
            :type="showPassword ? 'text' : 'password'"
            outlined
            required
            prepend-icon="mdi-key"
            :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
            :error-messages="errors"
            @click:append="showPassword = !showPassword"
          />
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          rules="required|min:6|confirmed:confirmation"
          name="Password (Confirm)"
        >
          <v-text-field
            v-model="confirm"
            label="Password (Confirm)"
            :type="showPassword ? 'text' : 'password'"
            outlined
            required
            prepend-icon="mdi-key"
            :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
            :error-messages="errors"
            @click:append="showPassword = !showPassword"
          />
        </validation-provider>
      </v-list-item-content>
      <v-list-item-action class="align-self-end pb-0 pb-sm-9">
        <v-btn
          large
          outlined
          color="primary"
          type="submit"
          :loading="loading"
          :disabled="!dirty || error !== null || !valid || loading"
        >
          Update
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <reauthenticate
      v-if="requiresReauthentication"
      @success="submit"
      @close="requiresReauthentication = false"
    />
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
import Reauthenticate from './Reauthenticate'
import { getAsyncCurrentUser } from '@/plugins/firebase'
export default {
  name: 'ChangePassword',
  components: {
    Reauthenticate
  },
  data: () => ({
    showPassword: false,
    password: '',
    confirm: '',
    loading: false,
    error: null,
    requiresReauthentication: false
  }),
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    async submit() {
      const form = this.$refs.form
      const formIsValid = await form.validate()
      if (!formIsValid) { return }
      this.loading = true
      try {
        const user = await getAsyncCurrentUser()
        await user.updatePassword(this.password)
        this.loading = false
        this.requiresReauthentication = false
        this.password = ''
        this.confirm = ''
        form.reset()
      } catch (error) {
        if (error.code === 'auth/requires-recent-login') {
          this.requiresReauthentication = true
          return
        }
        this.requiresReauthentication = false
        this.error = error
        this.loading = false
      }
    }
  }
}
</script>
