var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var dirty = ref.dirty;
return [(_vm.error)?_c('v-alert',{staticClass:"mb-0",attrs:{"dense":"","outlined":"","border":"left","type":"error"}},[_vm._v(" "+_vm._s(_vm.error.message)+" ")]):_vm._e(),_c('v-list-item',{staticClass:"wrapped"},[_c('v-list-item-content',[_c('validation-provider',{attrs:{"rules":"required|min:6","name":"Password","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Password","type":_vm.showPassword ? 'text' : 'password',"outlined":"","required":"","prepend-icon":"mdi-key","append-icon":_vm.showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline',"error-messages":errors},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|min:6|confirmed:confirmation","name":"Password (Confirm)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Password (Confirm)","type":_vm.showPassword ? 'text' : 'password',"outlined":"","required":"","prepend-icon":"mdi-key","append-icon":_vm.showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline',"error-messages":errors},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.confirm),callback:function ($$v) {_vm.confirm=$$v},expression:"confirm"}})]}}],null,true)})],1),_c('v-list-item-action',{staticClass:"align-self-end pb-0 pb-sm-9"},[_c('v-btn',{attrs:{"large":"","outlined":"","color":"primary","type":"submit","loading":_vm.loading,"disabled":!dirty || _vm.error !== null || !valid || _vm.loading}},[_vm._v(" Update ")])],1)],1),(_vm.requiresReauthentication)?_c('reauthenticate',{on:{"success":_vm.submit,"close":function($event){_vm.requiresReauthentication = false}}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }