<template>
  <v-container>
    <v-card flat>
      <v-card-title class="headline">
        My Account
      </v-card-title>
      <v-card-text>
        You may use the forms below to update your account information.
      </v-card-text>
      <v-card-text>
        <field-card title="Update Account Information">
          <change-display-name />
          <change-email />
          <v-alert
            v-if="!user.emailVerified"
            type="warning"
            prominent
            outlined
            dense
          >
            <v-list-item three-line class="wrapped">
              <v-list-item-content>
                <v-list-item-title>
                  Your email address is not verified.
                </v-list-item-title>
                <v-list-item-subtitle>
                  Please click the activation link in the email that was sent to your address.
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  color="warning"
                  @click="resendVerificationEmail"
                >
                  Resend Verification Email
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-alert>
        </field-card>
        <field-card title="Change Password">
          <change-password />
        </field-card>
        <field-card title="Newsletter">
          <v-switch
            v-model="newsletter"
            label="I authorize Mammut OS to occasionally send me news about the service."
            inset
            indete
            :loading="updatingNewsletter"
          />
        </field-card>
        <field-card title="Delete Account" disabled>
          <v-alert dense type="info" color="secondary" outlined>
            Account deletion will be made available with the release of version 1.0.
          </v-alert>
          <v-list-item three-line class="wrapped">
            <v-list-item-content>
              <v-list-item-title>
                Your account will be deleted and removed from all organizations.
              </v-list-item-title>
              <v-list-item-subtitle>
                Organizations with no other users will also be deleted along with with all linked data. All active subscriptions will be cancelled.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="error"
                outlined
                disabled
                @click="deleteAccount"
              >
                Proceed
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </field-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ChangeDisplayName from '@backend/components/auth/ChangeDisplayName'
import ChangeEmail from '@backend/components/auth/ChangeEmail'
import ChangePassword from '@backend/components/auth/ChangePassword'
export default {
  name: 'Account',
  components: {
    ChangeDisplayName,
    ChangeEmail,
    ChangePassword
  },
  data: () => ({
    loading: false,
    error: null,
    sendingVerificationEmail: false,
    updatingNewsletter: false,
    newsletter: true
  }),
  computed: {
    ...mapState(['userSettings']),
    ...mapGetters(['user'])
  },
  watch: {
    async newsletter(newsletter) {
      if (this.userSettings !== null && newsletter === this.userSettings.newsletter) {
        return
      }
      this.updatingNewsletter = true
      try {
        if (this.userSettings !== null) {
          await this.$db.collection('user-settings').doc(this.user.uid).update({ newsletter })
        } else {
          await this.$db.collection('user-settings').doc(this.user.uid).set({ newsletter })
        }
        this.$store.dispatch('newSnackbar', { type: 'success', message: 'Newsletter settings updated.' })
        this.updatingNewsletter = false
      } catch (error) {
        this.$store.dispatch('newSnackbar', { type: 'error', message: error })
        this.newsletter = this.userSettings ? this.userSettings.newsletter : true
        this.updatingNewsletter = false
      }
    }
  },
  mounted() {
    this.newsletter = this.userSettings ? this.userSettings.newsletter : true
  },
  methods: {
    async resendVerificationEmail() {
      this.sendingVerificationEmail = true
      try {
        const user = await this.$getAsyncCurrentUser()
        await user.sendEmailVerification({ url: 'https://backend.mammutos.com' })
        this.sendingVerificationEmail = false
      } catch (error) {
        alert(error)
        this.sendingVerificationEmail = false
      }
    },
    deleteAccount() {
      alert('delete')
    }
  }
}
</script>
