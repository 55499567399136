<template>
  <validation-observer
    ref="form"
    v-slot="{ valid, dirty }"
    tag="form"
    @submit.prevent="submit"
  >
    <v-alert
      v-if="error"
      dense
      outlined
      border="left"
      type="error"
      class="mb-0"
    >
      {{ error.message }}
    </v-alert>
    <v-list-item class="wrapped">
      <v-list-item-content>
        <validation-provider
          v-slot="{ errors }"
          rules="required|min:3"
          name="Display Name"
        >
          <v-text-field
            v-model="name"
            name="name"
            label="Display Name"
            outlined
            autocomplete="off"
            prepend-icon="mdi-account"
            :error-messages="errors"
          />
        </validation-provider>
      </v-list-item-content>
      <v-list-item-action class="align-self-start pt-0 pt-sm-1">
        <v-btn
          large
          outlined
          color="primary"
          type="submit"
          :loading="loading"
          :disabled="!dirty || error !== null || !valid || loading"
        >
          Update
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <reauthenticate
      v-if="requiresReauthentication"
      @success="submit"
      @close="requiresReauthentication = false"
    />
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
import Reauthenticate from './Reauthenticate'
import { getAsyncCurrentUser } from '@/plugins/firebase'
export default {
  name: 'ChangeDisplayName',
  components: {
    Reauthenticate
  },
  data: () => ({
    name: '',
    loading: false,
    error: null,
    requiresReauthentication: false
  }),
  computed: {
    ...mapGetters(['user'])
  },
  mounted() {
    this.name = this.user.displayName
  },
  methods: {
    async submit() {
      const form = this.$refs.form
      const formIsValid = await form.validate()
      if (!formIsValid) { return }
      this.loading = true
      try {
        const user = await getAsyncCurrentUser()
        await user.updateProfile({
          displayName: this.name
        })
        this.loading = false
        this.requiresReauthentication = false
      } catch (error) {
        if (error.code === 'auth/requires-recent-login') {
          this.requiresReauthentication = true
          return
        }
        this.requiresReauthentication = false
        this.error = error
        this.loading = false
      }
    }
  }
}
</script>
